.clip-container {
  overflow: hidden;
  display: block;
}

.clip-content {
  transform: translateY(300%);
}

.clip-container div {
  font-weight: 400;
  font-size: 8vw;
  line-height: 1.3;
}

@media screen and (max-width: 980px) {
  .clip-container div {
    /* font-size: 13.4vw; */
  }
}
@media screen and (max-width: 570px) {
  .clip-container div {
    /* font-size: 14vw; */
    margin-bottom: 0rem;
    font-size: 11vw;
  }
}
/* @media screen and (max-width: 340px) {
      .clip-container div {
        font-size: 17vw;
      }
} */
