.project {
  width: 70%;
  height: 80vh;
  margin: auto;
  position: relative;
  margin-bottom: 10rem;
  display: flex;
  top: 0;
  transition: all 0.6s;
}

.project__img {
  object-fit: cover;
  height: 80vh;
  width: 100%;
  cursor: pointer;
}

.project__content {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.666);
  padding: 2rem;
}

.project_name {
  font-size: 5rem;
  color: black;
}

.project_desc {
  font-size: 1.6rem;
  color: black;
}

.project__no {
  font-size: 20rem;
  position: absolute;
  bottom: -4rem;
  cursor: pointer;

  left: 0;
  color: rgba(255, 255, 255, 0.666);
}

.project__link {
  cursor: pointer;
  width: 15%;
  padding: 3rem;
  color: #000;
  font-weight: 800;
  background: rgba(255, 255, 255, 0.721);
  position: absolute;
  bottom: 0;
  right: 0;
}

.project__link svg {
  transform: rotate(45deg);
}

@media screen and (max-width: 800px) {
  .project {
    width: 100%;
    height: 100%;
    margin: 0;
    margin-bottom: 5rem;
  }
  .project__img {
    height: 68vh;
  }
  .project_name {
    font-size: 3rem;
  }
  .project__content {
    height: auto;
  }
  .project__no {
    font-size: 15rem;
    bottom: -3rem;
  }
}

@media screen and (max-width: 570px) {
  .project {
    width: 100%;
    height: 100%;
    margin: 0;
    margin-bottom: 8rem;
  }
  .project__img {
    height: 30vh;
  }
  .project__link {
    width: auto;
    padding: 2rem;
  }

  .project_name {
    font-size: 2rem;
  }
  .project__content {
    height: auto;
  }
  .project__no {
    font-size: 8rem;
    bottom: -1rem;
  }
}
