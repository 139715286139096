.projects {
  height: 80vh;
}

.projects__container {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: center;
  flex-direction: column;
  /* height: 70vh; */
}

.projects svg {
  transform: rotate(180deg);
}

.makeAbsolute {
  position: fixed;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 50%;
  width: 100vw;
  height: 100vh;
  z-index: 888;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 800px) {
  .projects__container {
    /* justify-content: unset; */
    /* flex-direction: row; */
    height: auto;
  }
}

@media screen and (max-width: 570px) {
  .projects,
  .projects__container {
    height: 100%;
  }
}
