main {
  position: relative;
  top: 0;
  overflow: hidden;
  min-height: calc(100vh - 16rem);
  display: flex;
  height: inherit;
  justify-content: space-between;
  align-items: center;
}

.Expanded {
  padding: 3rem;
  background: white;
  border: none;
  pointer-events: none;
  cursor: pointer;
}

.Expanded::before {
  content: "View Project";
  z-index: 5;
  position: absolute;
  cursor: pointer;
  top: 0;
  color: black;
  transform: translate(-50%, 25%);
  text-align: center;
  font-size: 1rem;
  width: 100%;
  height: 100%;
}

.photo img {
  height: 50rem;
  object-fit: cover;
  filter: grayscale(90%);
}

.all-svg {
  position: absolute;
  top: 67%;
  right: 0;
  display: inline;
}

.important_links a {
  margin-right: 2rem;
  font-size: 2rem;
}

@media screen and (max-width: 570px) {
  .all-svg {
    top: 72%;
  }
  .important_links {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .important_links a {
    margin-top: 1.5rem;
    font-size: 1.5rem;
  }
}
