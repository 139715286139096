@import url(https://use.typekit.net/qvn4bvh.css);


.cta{
    text-decoration: underline;
    
}

header{
   top: 0;
   height: 4.5rem;
}

ul{
    line-height: 3.5rem;
    position: relative;    
    display: flex;
    justify-content: space-between;
}


.link{
    display: flex;
    flex: 1 1;
    white-space: nowrap;
    flex-wrap: nowrap;
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    will-change: transform;
    opacity: 0;
}

.left{
    justify-content: flex-start;
}

.center{
    justify-content: center;
}

.right{
    justify-content: flex-end;
}



.header-border{
    height: 1px;   
    bottom: 0px;
    width: 100%;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    max-width: none;
    -webkit-transform-origin: left;
            transform-origin: left;
    position: absolute; 
}


  @media screen and (max-width: 570px) {
      .m-hide {
        display: none;
      }
}
body {
  font-family: aktiv-grotesk-extended, sans-serif;
  font-weight: 400;
  /* background-color: rgb(40,40,40); */
  font-style: normal;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* scroll-behavior: smooth; */
}
a {
  /* color: #fff; */
  font-size: 1.5rem;
}

main {
  position: relative;
  top: 0;
  overflow: hidden;
  min-height: calc(100vh - 16rem);
  display: flex;
  height: inherit;
  justify-content: space-between;
  align-items: center;
}

.Expanded {
  padding: 3rem;
  background: white;
  border: none;
  pointer-events: none;
  cursor: pointer;
}

.Expanded::before {
  content: "View Project";
  z-index: 5;
  position: absolute;
  cursor: pointer;
  top: 0;
  color: black;
  -webkit-transform: translate(-50%, 25%);
          transform: translate(-50%, 25%);
  text-align: center;
  font-size: 1rem;
  width: 100%;
  height: 100%;
}

.photo img {
  height: 50rem;
  object-fit: cover;
  -webkit-filter: grayscale(90%);
          filter: grayscale(90%);
}

.all-svg {
  position: absolute;
  top: 67%;
  right: 0;
  display: inline;
}

.important_links a {
  margin-right: 2rem;
  font-size: 2rem;
}

@media screen and (max-width: 570px) {
  .all-svg {
    top: 72%;
  }
  .important_links {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .important_links a {
    margin-top: 1.5rem;
    font-size: 1.5rem;
  }
}

.clip-container {
  overflow: hidden;
  display: block;
}

.clip-content {
  -webkit-transform: translateY(300%);
          transform: translateY(300%);
}

.clip-container div {
  font-weight: 400;
  font-size: 8vw;
  line-height: 1.3;
}

@media screen and (max-width: 980px) {
  .clip-container div {
    /* font-size: 13.4vw; */
  }
}
@media screen and (max-width: 570px) {
  .clip-container div {
    /* font-size: 14vw; */
    margin-bottom: 0rem;
    font-size: 11vw;
  }
}
/* @media screen and (max-width: 340px) {
      .clip-container div {
        font-size: 17vw;
      }
} */

#circle {
  width: 15vw;
  height: 15vw;
  font-size: 2rem;
  -webkit-animation-name: rotate;
          animation-name: rotate;
  -webkit-animation-duration: 12s;
          animation-duration: 12s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

@media screen and (max-width: 980px) {
  #circle {
    height: 25vw;
    width: 25vw;
  }
}

@media screen and (max-width: 570px) {
  #circle {
    width: 50vw;
    height: 50vw;
  }
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
  to {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
  to {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
}

#theme{
    position: absolute;
    top: 50%;
    z-index: 2;
    height: 2vw;
    width: 2vw;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    cursor: pointer;
    right: 7rem;  
}

  @media screen and (max-width: 980px) {
 #theme{
     height: 3vw; 
     width: 3vw;
 }
}


  @media screen and (max-width: 570px) {
      #theme {
        width: 6.5vw; 
        height: 6.5vw;
      }
}
.projects {
  height: 80vh;
}

.projects__container {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: center;
  flex-direction: column;
  /* height: 70vh; */
}

.projects svg {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.makeAbsolute {
  position: fixed;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 50%;
  width: 100vw;
  height: 100vh;
  z-index: 888;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

@media screen and (max-width: 800px) {
  .projects__container {
    /* justify-content: unset; */
    /* flex-direction: row; */
    height: auto;
  }
}

@media screen and (max-width: 570px) {
  .projects,
  .projects__container {
    height: 100%;
  }
}

.about {
  margin-top: 7rem;
}

.para {
  font-size: 5vw;
  margin-bottom: 4rem;
  margin-top: 7rem;
}

@media screen and (max-width: 570px) {
  .para {
    font-size: 8vw;
    margin-top: 0rem;
  }
  .about {
    margin-top: 2rem;
  }
}

.project {
  width: 70%;
  height: 80vh;
  margin: auto;
  position: relative;
  margin-bottom: 10rem;
  display: flex;
  top: 0;
  transition: all 0.6s;
}

.project__img {
  object-fit: cover;
  height: 80vh;
  width: 100%;
  cursor: pointer;
}

.project__content {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.666);
  padding: 2rem;
}

.project_name {
  font-size: 5rem;
  color: black;
}

.project_desc {
  font-size: 1.6rem;
  color: black;
}

.project__no {
  font-size: 20rem;
  position: absolute;
  bottom: -4rem;
  cursor: pointer;

  left: 0;
  color: rgba(255, 255, 255, 0.666);
}

.project__link {
  cursor: pointer;
  width: 15%;
  padding: 3rem;
  color: #000;
  font-weight: 800;
  background: rgba(255, 255, 255, 0.721);
  position: absolute;
  bottom: 0;
  right: 0;
}

.project__link svg {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

@media screen and (max-width: 800px) {
  .project {
    width: 100%;
    height: 100%;
    margin: 0;
    margin-bottom: 5rem;
  }
  .project__img {
    height: 68vh;
  }
  .project_name {
    font-size: 3rem;
  }
  .project__content {
    height: auto;
  }
  .project__no {
    font-size: 15rem;
    bottom: -3rem;
  }
}

@media screen and (max-width: 570px) {
  .project {
    width: 100%;
    height: 100%;
    margin: 0;
    margin-bottom: 8rem;
  }
  .project__img {
    height: 30vh;
  }
  .project__link {
    width: auto;
    padding: 2rem;
  }

  .project_name {
    font-size: 2rem;
  }
  .project__content {
    height: auto;
  }
  .project__no {
    font-size: 8rem;
    bottom: -1rem;
  }
}

.footer{
    padding: 4rem 0;
}

 .form_input{
    border: none;
    border-bottom: 2px solid;
    background-color: transparent;
    outline: none;
    width: 40%;
    font-family:aktiv-grotesk-extended, sans-serif;
    font-size: 3rem;
}

.overflow_container{
    overflow: hidden;
    display: block;
}

.form_button button{
    border-radius: 50%;
    width: 18rem;
    height: 18rem;
    font-size: 3rem;
    font-family: aktiv-grotesk-extended, sans-serif;
    border: 1px solid;
    background-color: transparent;
    margin-right: auto;
    display: block;
 cursor: pointer;

}

.form_button{
    display: flex;
       margin-top: 5rem;
    
}

.form_text{
    font-size: 5vw;
    font-weight: 400;
 
}

.form_field{
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;

}

 @media screen and (max-width: 570px) {
  .form_text {
      font-size: 7vw;
        width: 100%;
        margin: 1rem 0;
  }

.form_input{
        display: block;
    font-size: 5vw;
    margin: 2rem 0;
    width: 100%;
}

.form_button{
    display: block;
    margin: 1rem 0;
    
}

.socials{
    margin-top: 3rem;
}
}
.projectInfo {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100vh;
}

.project_header {
  position: fixed;
  top: 40px;
  width: 100%;
  padding: 0 6rem;
  z-index: 80;
}

.projectInfo_content {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.gif {
  height: auto;
}

aside {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  padding: 0 0 0 5rem;
}

.technologies {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
}

.p-0 {
  padding: 0 !important;
}
.listed {
  list-style: inside;
  flex-wrap: wrap;
  align-items: baseline;
}

.listed_items {
  width: 45%;
}

.text_huge {
  font-size: 15vw;
  width: 100%;
  display: flex;
  align-items: center;
}

.text_huge svg {
  width: 30%;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.projectInfo_name {
  text-align: center;
  color: black;
}
/* .projectInfo_image {
  width: 50%;
  height: 80vh;
} */

.project_role {
  font-size: 2rem;
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  width: 60%;
}

.project_role_col {
  width: auto;
  margin-right: 5rem;
}

.proj_desc {
  width: 60%;
}

.project_role_col p {
  white-space: nowrap;
}

.aside_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* .projectInfo .clip-content{ */
/* font-size: 15vw; */
/* } */

.container_other {
  padding: 0 !important;
  margin: 0;
}

.m-mt-l {
  margin-top: 4rem;
}

.website_link {
  opacity: 1;
  color: "black";
  font-size: 3rem;
}

@media screen and (max-width: 800px) {
  aside {
    padding: 0 2rem;
    height: auto;
  }
  aside:nth-of-type(1) {
    height: 42vh;
    align-items: flex-end;
  }
  aside:nth-of-type(2) {
    /* height: 60vh; */
    /* padding: 0; */
    margin: 5rem 0;
  }
  .listed_items {
    width: 100%;
  }
  .project_role {
    width: 100%;
    flex-direction: column;
  }
  .text_huge {
    width: 100%;
    display: block;
  }
  .proj_desc {
    width: 100%;
  }
  .aside_content {
    display: flex;
    flex-direction: column;
  }
  .projectInfo_content {
    display: block;
  }
  .gif {
    width: 100vw;
    margin: 5rem 0;
  }
  .project_header {
    /* position: fixed; */
    top: 15px;
    /* width: 100%; */
    padding: 0 2rem;
  }
}

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: none;
  -webkit-user-drag: none;
  outline: none;
  outline: 0;
  -webkit-tap-highlight-color: transparent;
  text-rendering: optimizeSpeed;
}

html {
  /* scroll-behavior: smooth; */
  font-size: 62.5%;
}
body {
  box-sizing: border-box;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

@media screen and (max-width: 570px) {
  /* body{
    overflow-x: scroll;
  } */
}

ul {
  list-style-type: none;
}

/* ::-webkit-scrollbar{
    display: none;
  } */

h1 {
  font-size: 6rem;
  line-height: 1;
}

h2 {
  font-size: 5rem;
  line-height: 1;
}

h3 {
  font-size: 4rem;
  line-height: 1;
}

h4 {
  font-size: 3rem;
  line-height: 1;
}

h5 {
  font-size: 2rem;
  line-height: 1;
}

h6 {
  font-size: 1rem;
  line-height: 1;
}

p {
  font-size: 1.6rem;
  line-height: 1;
}

.mb-l {
  margin-bottom: 4rem;
}
.ml-xl {
  margin-left: 8rem;
}
.mt-l {
  margin-top: 4rem;
}

.ml-s {
  margin-left: 1rem;
}
.ml-m {
  margin-left: 3.5rem;
}

li {
  font-size: 1.4rem;
}

.section-space {
  margin-bottom: 20rem;
}

.container {
  padding: 4rem 6rem 0;
  margin: 0 auto;
  /* overflow:hidden; */
}

@media screen and (max-width: 980px) {
  .container {
    padding: 2rem 2rem 0;
  }
}

@media screen and (max-width: var(--xs-breakpoint)) {
  .container {
    padding: 2rem 1rem 0;
  }
}

@media screen and (max-width: var(--mobile-breakpoint)) {
  body,
  html {
    font-size: 50%;
  }
}

@media screen and (max-width: var(--xs-breakpoint)) {
  body,
  html {
    font-size: 30%;
  }
}

